@font-face {
    font-family: "Booty";
    src: url("assets/Booty.ttf") format("truetype");
}

body {
    background-color: black;
    color: white;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%), url(assets/randombishop_scruffy_5.png);
    background-repeat: no-repeat;
    background-position: top right;
    font-family: sans-serif;
}

a {
    color: #16e951;
}

#intro {
    margin-top: 375px
}

#socials {
    text-align: right
}

#socials {
    margin-top: 50px
}

.title1 {
    font-family: Booty;
    font-size: 16px;
    color: #16e951;
    letter-spacing: 8px;
    text-transform: uppercase;
    margin-bottom: -6px;
}

.title2 {
    font-family: Booty;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 15px;
}

.text {
    font-size: 18px
}

.socials-link {
    color: white;
    font-size: 24px;
    margin-right: 15px;
}

.credits {
    color: #16e951;
    font-family: monospace;
    font-size: 10px;
    text-align: right;
    margin-bottom: 25px;
}



